<template>
    <div :class="`hover:bg-blue-100 transition-colors cursor-pointer flex justify-between ${urgent >= 24 && ticket.from == 'amazon' ? 'bg-red-100' : 'bg-gray-50'} shadow relative mb-3 py-3 px-10`">
        <div>
            <router-link :to="`/tickets/${ticket.id}`" class="flex">
                <span :class="`${solved == true ? 'bg-green-500' : 'bg-red-500'} w-2 h-full absolute top-0 left-0`"></span>
                <p class="text-2xl">
                    <span class="max">
                        {{ ticketTitle }}
                    </span></p>
                <p class="text-2xl text-left">
                    <span class="customer">
                        {{ customer }}
                    </span>
                </p>
                <p>
                    <span :class="`date text-2xl ${urgent >= 24 && ticket.from == 'amazon' ? 'text-black' : 'text-gray-400'}`">{{ formatDate(ticket.raw_created_at) }}</span>
                </p>
                <p v-if="urgent >= 24 && ticket.from == 'amazon'" class="urgent">
                    +{{ Math.abs(2 - urgent) }} Std
                </p>
                <div v-if="!solved" class="tooltip absolute shadow-2xl p-10" style="background-color:#F8F8F8">
                    <h2 class="font-bold mb-5">{{ ticket.subject }}</h2>
                    <p class="leading-relaxed">{{ commentTrunc }}</p>
                </div>
            </router-link>
        </div>
        <div class="mx-auto">
            <div class="icon-star" @click="toggleStar">
                <svg :class="{'filled': isStarred}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-star">
                    <polygon points="12 2 15 8 21 9 17 14 18 21 12 18 6 21 7 14 3 9 9 8 12 2"></polygon>
                </svg>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>

.icon-star svg {
    width: 24px;
    height: 24px;
    cursor: pointer;
    // transition: fill 0.3s ease;
}
.icon-star svg.filled {
    fill: black;
    stroke: black;
}
.urgent {
    position: absolute;
    right: 0;
    top: 0;
    padding: 10px;
    background: red;
    color: #fff;
    font-weight: bold;
    font-size: 14px;
}
    .tooltip {
        top: 100%;
        left: 5%;
        font-size: 15px;
        max-width: 50%;
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        transition: opacity 300ms ease;
        z-index: 10;
    }
    .ticket:nth-child(2) {
        z-index: 2;
    }
    @for $i from 1 through 5 {
        .ticket:nth-last-child(#{$i}):not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(3)):not(:nth-child(4)):not(:nth-child(5)) {
            .tooltip {
                top: unset;
                bottom: 80%;
            }
        }
    }
    // Removed temporarily hover info
    // .ticket:hover {
    //     .tooltip {
    //         opacity: 1;
    //         visibility: visible;
    //         pointer-events: all;
    //     }
    // }
    p span.max {
        min-width: 52rem;
        display: block;
        font-weight: 600;
    }
    .customer {
        min-width: 30rem;
        display: block;
    }
    .date {
        min-width: 24rem;
        display: block;
    }
    @media screen and (max-width: 576px) {
        p span.max {
            width: 21rem !important;
            word-break: break-all;
            min-width: unset;
        }
        .customer {
            margin-left: 2rem;
            width: 12rem;
            min-width: unset;
            word-break: break-all;
        }
        .date {
            min-width: unset;
            width: 100%;
            word-break: break-all;
            margin-left: 2rem;
        }
        .ticket:hover {
            .tooltip {
                display: none;
            }
        }
    }
</style>

<script>
export default {
    props: ['solved', 'ticket', 'urgent'],
    data() {
        return {
            comment: '',
            isStarred: false,
            starredTickets: []
        }
    },
    mounted() {
        if(!this.solved) {
            this.comment = '';
        }
        this.updateStarStatus();
        this.$root.$on('favorites-cleared', this.clearStarStatus);
        this.$root.$on('favourite-sidebar-updated', this.removeTicketFromMainContainer);
    },
    beforeDestroy() {
        this.$root.$off('favourite-sidebar-updated', this.removeTicketFromMainContainer);
        this.$root.$off('favorites-cleared', this.clearStarStatus);
    },
    computed: {
        ticketTitle() {
            if(this.ticket.subject.length > 60) {
                return this.ticket.subject.substr(0, 60) + "...";
            } else {
                return this.ticket.subject;
            }
        },
        commentTrunc() {
            if(this.comment.length > 300) {
                return this.comment.slice(0, 300) + '...';
            } else {
                return this.comment;
            }
        },
        customer() {
            return this.ticket.customer;
            // return decodeURIComponent(escape(this.ticket.customer));
        }
    },
    methods:{
        removeTicketFromMainContainer() {
            this.updateStarStatus()
        },
        formatDate(dateString) {
            const date = new Date(dateString);
            const day = date.getDate();
            const month = date.toLocaleString('default', { month: 'short' });
            const year = date.getFullYear();
            return `${day}-${month}-${year}`;
        },
        toggleStar() {
            this.isStarred = !this.isStarred;
            let starredTickets = JSON.parse(localStorage.getItem('favouritedTickets')) || [];
            if (!Array.isArray(starredTickets)) {
                starredTickets = [];
            }

            const ticketData = {
                ticketId: this.ticket.id,
                ticketCustomer: this.ticket.customer,
                ticketSubject: this.ticket.subject,
                ticketStarred: this.isStarred
            };

            if (this.isStarred) {
                if (!starredTickets.some(ticket => ticket.ticketId === this.ticket.id)) {
                    starredTickets.push(ticketData);
                }
            } else {
                starredTickets = starredTickets.filter(ticket => ticket.ticketId !== this.ticket.id);
            }
            localStorage.setItem('favouritedTickets', JSON.stringify(starredTickets));
            this.$root.$emit('favourite-updated', { ticket: ticketData, isStarred: this.isStarred });

        },
        updateStarStatus() {
            let starredTickets = JSON.parse(localStorage.getItem('favouritedTickets')) || [];
            if (!Array.isArray(starredTickets)) {
                starredTickets = [];
            }
            this.isStarred = starredTickets.some(ticket => ticket.ticketId === this.ticket.id);
        },
        clearStarStatus() {
            this.isStarred = false;
        }
    }
}
</script>