<template>
    <div class="tickets flex flex-col">
        <div v-if="!solved" class="tabs mb-10 border-b-2 border-blue-400 flex justify-between">
            <div @click="getFrom('amazon')" ref="amazon" class="tab cursor-pointer text-center flex-1 text-3xl py-5">
                Amazon <span class="badge">{{ amazonCount }}</span>
            </div>
            <div @click="getFrom('ebay')" ref="ebay" class="tab cursor-pointer text-center flex-1 text-3xl py-5">
                Ebay <span class="badge">{{ ebayCount }}</span>
            </div>
            <div @click="getFrom('va')" ref="va" class="tab cursor-pointer text-center flex-1 text-3xl py-5">
                VA <span class="badge">{{ vaCount }}</span>
            </div>
            <div @click="getFrom('info')" ref="info" class="tab cursor-pointer text-center flex-1 text-3xl py-5">
                Info <span class="badge">{{ infoCount }}</span>
            </div>
            <div @click="getFrom('facebook')" ref="facebook" class="tab cursor-pointer text-center flex-1 text-3xl py-5">
                Facebook <span class="badge">{{ facebookCount }}</span>
            </div>
            <div @click="getFrom('stobbe')" ref="stobbe" class="tab cursor-pointer text-center flex-1 text-3xl py-5">
                Technische <span class="badge">{{ stobbeCount }}</span>
            </div>
            <div @click="getFrom('unknown')" ref="unknown" class="tab cursor-pointer text-center flex-1 text-3xl py-5">
                Nicht Zugeordnet <span class="badge">{{ unknownCount }}</span>
            </div>
        </div>
        <!-- <span class="font-bold text-2xl mb-5 w-full" style="width: 30rem">{{ticketsPaginated[0]}}</span> -->
        <div class="cols px-10 flex justify-between">
            <h2 class="font-bold text-2xl mb-5 w-full" style="width: 52rem">Betreff</h2>
            <h2 class="font-bold text-2xl mb-5 w-full" style="width: 30rem">Name</h2>
            <h2 class="font-bold text-2xl mb-5 w-full cursor-pointer" style="width: 24rem" @click="changeTimeOrder">Zeit <i :class="`ml-2 fas fa-caret-${caret}`"></i></h2>
            <h2 class="font-bold text-2xl mb-5 w-full" style="width: 12rem">Favoriten</h2>
            <!-- <h2 class="font-bold text-2xl mb-5 w-full cursor-pointer" style="width: 12rem" @click="clearFavourites">klare Favoriten</h2> -->
        </div>
        <div class="tickets-list" v-if="!loading">
            <Ticket :solved="solved" v-for="ticket in ticketsPaginated" :ticket="ticket" :key="ticket.id" :urgent="checkUrgent(ticket.raw_created_at)"/>
            <p v-if="!loading && !setTickets.length && !solved" class="text-4xl text-center mt-10 font-bold">Hier gibt es keine Tickets für Sie!</p>
        </div>
        <div class="tickets-list relative" v-if="loading">
            <Loading class="absolute top-1/2 left-1/2 transform-translate-x-2/4"></Loading>
        </div>
        <!-- OLD PAGINATION -->
        <!-- <Pagination v-if="!solved"/> -->
    </div>
</template>

<style lang="scss">
    .tickets-list {
        height: 658px;
        overflow-y: auto;
    }
    .tickets-list::-webkit-scrollbar {
        width: 10px;
    }
    
    .tickets-list::-webkit-scrollbar-track {
        background-color: rgb(204, 227, 255);
    }
    
    .tickets-list::-webkit-scrollbar-thumb {
        background-color: rgb(96,165,250);
    }
    .tab {
        position: relative;
    }
    .badge {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: rgb(96,165,250);
        color: #fff;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-left: 5px;
        font-size: 14px;
        font-weight: 600;
        position: absolute;
        top: 0;
    }

    @media screen and (max-width: 576px) {
        .tickets {
            margin-left: 0px;
            &-list {
                height: 500px;
            }
        }
        .tickets-list::-webkit-scrollbar {
            width: 4px;
        }
        .badge {
            width: 15px;
            height: 15px;
            font-size: 10px;
        }
        .tab {
            min-width: 100px;
        }
        .tabs {
            overflow-x: scroll;
        }
        @media screen and (max-width: 576px) {
            .cols {
                h2:first-child {
                    width: 30rem !important;
                }
                h2:nth-child(2) {
                    width: 20rem !important;
                }
            }
        }
    }
</style>

<script>
    import Ticket from '@/components/Ticket.vue';
    import Pagination from '@/components/Pagination.vue';
    import { mapActions, mapGetters } from 'vuex';

    export default {
        props: ['solved', 'filter', 'openFilter', 'datetime'],
        data() {
            return {
                setTickets: [],
                desc: false,
                from: 'all'
            }
        },
        components: {
            Ticket,
            Pagination
        },
        methods: {
            ...mapActions(['getTickets', 'getSolvedByDate']),
            getFrom(from) {
                const selected = document.querySelector(".border-b-4.border-blue-400");
                if(selected) {
                    selected.classList.remove('border-b-4');
                    selected.classList.remove('border-blue-400');
                }
                
                switch(from) {
                    case 'amazon': {
                        this.setTickets = this.tickets.filter(el => el.from == 'amazon');
                        this.from = 'amazon';
                        this.$refs.amazon.classList.add("border-b-4");
                        this.$refs.amazon.classList.add("border-blue-400");
                        window.localStorage.setItem('favorite', 'amazon');
                        break;
                    }
                    case 'ebay': {
                        this.setTickets = this.tickets.filter(el => el.from == 'ebay');
                        this.from = 'ebay';
                        this.$refs.ebay.classList.add("border-b-4");
                        this.$refs.ebay.classList.add("border-blue-400");
                        window.localStorage.setItem('favorite', 'ebay');
                        break;
                    }
                    case 'va': {
                        this.setTickets = this.tickets.filter(el => el.from == 'va');
                        this.from = 'va';
                        this.$refs.va.classList.add("border-b-4");
                        this.$refs.va.classList.add("border-blue-400");
                        window.localStorage.setItem('favorite', 'va');
                        break;
                    }
                    case 'info': {
                        this.setTickets = this.tickets.filter(el => el.from == 'info');
                        this.from = 'info';
                        this.$refs.info.classList.add("border-b-4");
                        this.$refs.info.classList.add("border-blue-400");
                        window.localStorage.setItem('favorite', 'info');
                        break;
                    }
                    case 'stobbe': {
                        this.setTickets = this.tickets.filter(el => el.from == 'stobbe');
                        this.from = 'stobbe';
                        this.$refs.stobbe.classList.add("border-b-4");
                        this.$refs.stobbe.classList.add("border-blue-400");
                        window.localStorage.setItem('favorite', 'stobbe');
                        break;
                    }
                    case 'facebook': {
                        this.setTickets = this.tickets.filter(el => el.from == 'facebook');
                        this.from = 'facebook';
                        this.$refs.facebook.classList.add("border-b-4");
                        this.$refs.facebook.classList.add("border-blue-400");
                        window.localStorage.setItem('favorite', 'facebook');

                        break;
                    }
                    case 'unknown': {
                        this.setTickets = this.tickets.filter(el => el.from == '');
                        this.from = 'unknown';
                        this.$refs.unknown.classList.add("border-b-4");
                        this.$refs.unknown.classList.add("border-blue-400");
                        window.localStorage.setItem('favorite', 'unknown');

                        break;
                    }
                }
            },
            changeTimeOrder() {
                this.desc = !this.desc;
                this.ticketsPaginated.sort((a, b) => {

                    const dateA = new Date(a.raw_created_at)
                    const dateB = new Date(b.raw_created_at);

                    // Sort based on the difference between the dates
                    if (!this.desc) {
                        return dateA - dateB; // Asc order
                    } else {
                        return dateB - dateA; // Desc order
                    }
                    // if(!this.desc) {
                    //     return new Date(a.raw_created) - new Date(b.raw_created)
                    // } else {
                    //     return new Date(b.raw_created) - new Date(a.raw_created)
                    // }
                })
            },
            checkUrgent(date) {
                const diff = Math.floor(Math.abs(new Date() - new Date(date)) / 36e5);
                return diff;
            }
        },
        mounted() {
            if(!this.solved) {
                const fav = window.localStorage.getItem('favorite');
                if(fav) {
                    switch(fav) {
                            case 'amazon': {
                                this.$refs.amazon.classList.add("border-b-4");
                                this.$refs.amazon.classList.add("border-blue-400");
                                break;
                            }
                            case 'ebay': {
                                this.$refs.ebay.classList.add("border-b-4");
                                this.$refs.ebay.classList.add("border-blue-400");
                                break;
                            }
                            case 'va': {
                                this.$refs.va.classList.add("border-b-4");
                                this.$refs.va.classList.add("border-blue-400");
                                break;
                            }
                            case 'info': {
                                this.$refs.info.classList.add("border-b-4");
                                this.$refs.info.classList.add("border-blue-400");
                                break;
                            }
                            case 'facebook': {
                                this.$refs.facebook.classList.add("border-b-4");
                                this.$refs.facebook.classList.add("border-blue-400");
                                break;
                            }
                            case 'stobbe': {
                                this.$refs.stobbe.classList.add("border-b-4");
                                this.$refs.stobbe.classList.add("border-blue-400");
                                break;
                            }
                            case '': {
                                this.$refs.unknown.classList.add("border-b-4");
                                this.$refs.unknown.classList.add("border-blue-400");
                                break;
                            }
                            default: {
                                this.$refs.amazon.classList.add("border-b-4");
                                this.$refs.amazon.classList.add("border-blue-400");
                            }
                        }
                }
            }
        },
        computed: {
            ...mapGetters(['loggedUser', 'tickets', 'solvedTickets', 'page', 'loading']),
            ticketsPaginated() {
                if(!this.solved) {
                    if(window.localStorage.getItem('favorite')) {
                        this.setTickets = this.tickets.filter(el => {
                            return el.from == window.localStorage.getItem('favorite');
                        });
                        // OLD PAGINATION
                        // return this.ticketsFiltered.slice(this.page * 14, (this.page * 14 + 14)).filter(el => {
                        //     return el.from == window.localStorage.getItem('favorite');
                        // });
                        return this.ticketsFiltered.filter(el => {
                            return el.from == window.localStorage.getItem('favorite');
                        });
                    }
                    // OLD PAGINATION
                    // return this.ticketsFiltered.slice(this.page * 14, (this.page * 14 + 14));
                    return this.ticketsFiltered;
                } else {
                    return this.solvedTicketsFiltered;
                }
                
            },
            solvedTicketsFiltered() {
                return this.solvedTickets.filter(el => {
                    return el.subject.toLowerCase().includes(this.filter.toLowerCase()) ||
                    (el.orderId && el.orderId.includes(this.filter))
                })
            },
            ticketsFiltered() {
                if(this.setTickets.length) {    
                    return this.setTickets.filter(el => {
                        return el.subject.toLowerCase().includes(this.openFilter.toLowerCase()) 
                        || (el.orderId && el.orderId.includes(this.openFilter)) 
                        || (el.customer.toLowerCase().includes(this.openFilter.toLowerCase()))
                    })
                } else {
                    return this.tickets.filter(el => {
                        return el.subject.toLowerCase().includes(this.openFilter.toLowerCase())
                        || (el.orderId && el.orderId.includes(this.openFilter))
                        || (el.customer.toLowerCase().includes(this.openFilter.toLowerCase()))
                    })
                }
            },
            ticketType() {
                if(this.solved) {
                    return this.solvedTicketsFiltered;
                } else {
                    return this.ticketsPaginated;
                }
            },
            caret() {
                if(this.desc) {
                    return 'down';
                } else {
                    return 'up'
                }
            },
            amazonCount() {
                return this.tickets.filter(el => el.from == 'amazon').length;
            },
            ebayCount() {
                return this.tickets.filter(el => el.from == 'ebay').length;
            },
            vaCount() {
                return this.tickets.filter(el => el.from == 'va').length;
            },
            facebookCount() {
                return this.tickets.filter(el => el.from == 'facebook').length;
            },
            infoCount() {
                return this.tickets.filter(el => el.from == 'info').length;
            },
            stobbeCount() {
                return this.tickets.filter(el => el.from == 'stobbe').length;
            },
            unknownCount() {
                return this.tickets.filter(el => el.from == 'unknown').length;
            },
        },
        watch: {
            datetime: function(newVal, oldVal) {
                if(newVal.length) {
                    this.getSolvedByDate({
                        date: newVal,
                        tag: this.loggedUser.tag
                    });
                }
            },
            tickets: function(newVal, oldVal) {
                if(!this.solved) {
                    let favorite = 'amazon';// you will be misded :'( olti
                    if(window.localStorage.getItem('favorite')) {
                        const fav = window.localStorage.getItem('favorite');
                        switch(fav) {
                            case 'amazon': {
                                favorite = 'amazon';
                                this.$refs.amazon.classList.add("border-b-4");
                                this.$refs.amazon.classList.add("border-blue-400");
                                break;
                            }
                            case 'ebay': {
                                favorite = 'ebay';
                                this.$refs.ebay.classList.add("border-b-4");
                                this.$refs.ebay.classList.add("border-blue-400");
                                break;
                            }
                            case 'va': {
                                favorite = 'va';
                                this.$refs.va.classList.add("border-b-4");
                                this.$refs.va.classList.add("border-blue-400");
                                break;
                            }
                            case 'info': {
                                favorite = 'info';
                                this.$refs.info.classList.add("border-b-4");
                                this.$refs.info.classList.add("border-blue-400");
                                break;
                            }
                            case 'facebook': {
                                favorite = 'facebook';
                                this.$refs.facebook.classList.add("border-b-4");
                                this.$refs.facebook.classList.add("border-blue-400");
                                break;
                            }
                            case 'stobbe': {
                                favorite = 'stobbe';
                                this.$refs.stobbe.classList.add("border-b-4");
                                this.$refs.stobbe.classList.add("border-blue-400");
                                break;
                            }
                            case 'unknown': {
                                favorite = 'unknown';
                                this.$refs.unknown.classList.add("border-b-4");
                                this.$refs.unknown.classList.add("border-blue-400");
                                break;
                            }
                            default: {
                                favorite = 'amazon';
                                this.$refs.amazon.classList.add("border-b-4");
                                this.$refs.amazon.classList.add("border-blue-400");
                            }
                        }
                    }
                    this.setTickets = newVal.filter(el => el.from == favorite);
                }
                
            },

        }
    }
</script>
