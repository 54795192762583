<template>
    <div class="pagination my-10 self-end flex items-center">
        <button class="text-2xl text-blue-400 font-medium mr-5" @click="goBack">Zurück</button>
        <button class="text-2xl text-blue-400 font-medium mr-5" @click="goForward">Weiter</button>
        <div class="numbers flex items-center">
            <div v-if="nrPages < 7" class="flex items-center">
                <button @click="setNrPage(i)" v-for="i in nrPages" :key="i" :class="'text-2xl bg-white hover:bg-blue-400 hover:text-white transition-colors text-black font-semibold w-16 h-16 flex items-center justify-center mr-5 ' + checkClass(i)">{{ i }}</button>
            </div>
            <div v-else class="flex items-center">
                <button @click="setNrPage(i)" v-for="i in nrPages" :key="i" :class="'text-2xl bg-white hover:bg-blue-400 hover:text-white transition-colors text-black font-semibold w-16 h-16 flex items-center justify-center mr-5 ' + checkClass(i)">{{ i }}</button>
                <!-- <span class="w-3 h-3 bg-blue-400 rounded mr-5 self-end"></span>
                <span class="w-3 h-3 bg-blue-400 rounded mr-5 self-end"></span>
                <span class="w-3 h-3 bg-blue-400 rounded mr-5 self-end"></span>
                <button @click="setNrPage(nrPages - 1)" :class="'text-2xl bg-white hover:bg-blue-400 hover:text-white transition-colors text-black font-semibold w-16 h-16 flex items-center justify-center mr-5 ' + checkClass(nrPages - 1)">{{ nrPages - 1 }}</button>
                <button @click="setNrPage(nrPages)" :class="'text-2xl bg-white hover:bg-blue-400 hover:text-white transition-colors text-black font-semibold w-16 h-16 flex items-center justify-center mr-5 ' + checkClass(nrPages)">{{ nrPages }}</button> -->
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    button:focus {
        outline: none;
    }
    .active {
        background: rgb(96, 165, 250) !important;
        color: #fff;
    }
</style>

<script>
    import { mapGetters, mapActions } from 'vuex';

    export default {
        methods: {
            ...mapActions(['setPage']),
            setNrPage(page) {
                this.setPage(page - 1);
            },
            checkClass(pageNr) {
                if(pageNr - 1 == this.page) {
                    return "active"
                }
            },
            goBack() {
                if(this.page == 0) {
                    this.setPage(this.nrPages - 1);
                } else {
                    this.setPage(this.page - 1);
                }
            },
            goForward() {
                if(this.page == this.nrPages - 1) {
                    this.setPage(0);
                } else {
                    this.setPage(this.page + 1);
                }
            },
        },
        computed: {
            ...mapGetters(['tickets', 'page']),
            nrPages() {
                return Math.ceil(this.tickets.length / 15);
            },
        }
    }
</script>